import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from "./components/pages/home/home.component";
import { EventiteComponent } from "./components/pages/eventite/eventite.component";

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            title: 'Omnysa',
            navClass: 'default-navbar',
            logoColor: 'blue'
        }
    },
    {
        path: 'eventite',
        component: EventiteComponent,
        data: {
            title: 'Omnysa :: Eventite',
            navClass: 'white-navbar',
            logoColor: 'white'
        }
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
