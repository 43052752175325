import { Component, OnInit } from '@angular/core';
import * as config from '../../../../config.json';
import { ActivationStart, Router } from "@angular/router";
import { faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { filter } from "rxjs/operators";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    protected navClass = 'default-navbar';
    protected logoColor = 'blue';
    public isDropdownOpen = false;
    public links = config.company.links;

    public phone = config.company.phone;
    public email = config.company.email;

    public iconPhone = faMobileAlt;
    public iconEmail = faEnvelope;

    constructor(
        private router: Router
    ) { }

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof ActivationStart)
        ).subscribe((event) => {
            if (event['snapshot'] && event['snapshot'].data) {
                if (event['snapshot'].data.navClass) {
                    this.navClass = event['snapshot'].data.navClass;
                }

                if (event['snapshot'].data.logoColor) {
                    this.logoColor = event['snapshot'].data.logoColor;
                }
            }
        });
    }

    public getNavClass(): string {
        return `navbar ${this.navClass}`;
    }

    public toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }
}
