import { Component, HostListener, OnInit } from '@angular/core';
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import * as config from '../../../../../config.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    public email = config.company.email;

    public iconCaretRight = faCaretRight;

    public logoRotationAngle = 0;

    constructor() { }

    ngOnInit() {
    }

    public logoRotationStyle(clockwise: boolean = true): string {
        const angle = this.logoRotationAngle * (clockwise ? 1 : -1);
        return `rotate(${angle}deg)`;
    }

    @HostListener('window:scroll', ['$event'])
    public updateLogoRotationAngle(event) {
        this.logoRotationAngle = (window.pageYOffset / 5) % 360;
    }
}
