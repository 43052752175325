import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

    public constructor(
        private router: Router,
        private titleService: Title
    ) {}

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.router)
        ).subscribe(event => {
            const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(' :: ');
            this.titleService.setTitle(title);

            window.scrollTo(0, 0);
        });
    }

    protected getTitle(state, parent) {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push(parent.snapshot.data.title);
        }

        if (state && parent) {
            data.push(... this.getTitle(state, state.firstChild(parent)));
        }

        return data;
    }
}
