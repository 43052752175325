import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        const amount = parseFloat(value);

        if (isNaN(amount)) {
            return '-';
        }

        return '$' + amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

}
