import { Component, OnInit } from '@angular/core';
import { faLinkedin, faTwitter, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import * as config from '../../../../config.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    public fullName = config.company.fullName;
    public shortName = config.company.shortName;
    public address = config.company.address;
    public phone = config.company.phone;
    public email = config.company.email;
    public social = config.company.social;

    public copyrightYear: number;

    public iconPhone = faMobileAlt;
    public iconEmail = faEnvelope;
    public iconLinkedIn = faLinkedin;
    public iconTwitter = faTwitter;
    public iconFacebook = faFacebookSquare;

    constructor() {
        this.copyrightYear = new Date().getFullYear();
    }

    ngOnInit() {
    }

}
