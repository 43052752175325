import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PhonePipe } from './pipes/phone.pipe';
import { HomeComponent } from './components/pages/home/home.component';
import { EventiteComponent } from './components/pages/eventite/eventite.component';
import { ReactiveFormsModule } from "@angular/forms";
import { MoneyPipe } from './pipes/money.pipe';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        PhonePipe,
        HomeComponent,
        EventiteComponent,
        MoneyPipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FontAwesomeModule
    ],
    providers: [
        Title
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
