import { Component, OnInit } from '@angular/core';
import { FormBuilder } from "@angular/forms";

@Component({
  selector: 'app-eventite',
  templateUrl: './eventite.component.html',
  styleUrls: ['./eventite.component.scss']
})
export class EventiteComponent implements OnInit {

    public profitCalculatorForm = this.fb.group({
        ticketsSold: [350],
        faceValue: [20],
        serviceFeePercentage: [25],
    });

    protected ticketsPerTransaction = 1.5;

    protected fees = {
        platform: {
            flat: .97,
            rate: .02
        },
        processor: {
            flat: .3,
            rate: .029
        },
        competitor: {
            flat: 1.49,
            rate: .25
        }
    };

    constructor(
        private fb: FormBuilder
    ) { }

    ngOnInit() {
        this.profitCalculatorForm.get('faceValue').valueChanges.subscribe(val => {
            const rate = Math.floor((this.competitorTicketTotalCost - this.fees.competitor.flat - this.faceValue) / this.faceValue * 100);
            this.profitCalculatorForm.controls['serviceFeePercentage'].setValue(rate);
        });
    }

    get ticketsSold(): number {
        return parseInt(this.profitCalculatorForm.controls['ticketsSold'].value);
    }

    get faceValue(): number {
        return parseFloat(this.profitCalculatorForm.controls['faceValue'].value);
    }

    get serviceFeeRate(): number {
        return parseFloat(this.profitCalculatorForm.controls['serviceFeePercentage'].value) / 100;
    }

    get ticketTotalCost(): number {
        return this.faceValue + (this.faceValue * this.serviceFeeRate) + this.fees.platform.flat + (this.faceValue * this.fees.platform.rate);
    }

    get competitorTicketTotalCost(): number {
        return this.faceValue + (this.faceValue * this.fees.competitor.rate) + this.fees.competitor.flat;
    }

    get faceValueTotal(): number {
        return this.ticketsSold * this.faceValue;
    }

    get serviceFeeTotal(): number {
        return this.faceValueTotal * this.serviceFeeRate;
    }

    get platformFeeTotal(): number {
        const percentageFee = this.grossRevenue * this.fees.platform.rate;
        const flatFee = this.ticketsSold * this.fees.platform.flat;

        return percentageFee + flatFee;
    }

    get grossRevenue(): number {
        return this.faceValueTotal + this.serviceFeeTotal;
    }

    get processedTotal(): number {
        return this.grossRevenue + this.platformFeeTotal;
    }

    get processorFee(): number {
        const percentageFee = this.processedTotal * this.fees.processor.rate;
        const flatFee = (this.ticketsSold / this.ticketsPerTransaction) * this.fees.processor.flat;

        return percentageFee + flatFee;
    }

    get netRevenue(): number {
        return this.grossRevenue - this.processorFee;
    }

    get netRevenueIncrease(): number {
        return this.netRevenue - this.faceValueTotal;
    }

    get additionalReveneuePercentageIncrease(): number {
        return this.netRevenueIncrease / this.faceValueTotal;
    }

    get additionalTicketEquivalence(): number {
        return Math.floor(this.netRevenueIncrease / this.faceValue);
    }
}
